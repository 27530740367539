// src/components/PrintInvoice.js
import React, { forwardRef } from 'react';
import './PrintInvoice.css'; // You can define styles for the invoice here

const PrintInvoice = forwardRef(({ sales, complains }, ref) => {
  const customer = [...new Set(sales.map(sale => sale.customer))];
  const totalGoodComplains = complains.reduce((total, complain) => total + (complain.amount * complain.qty), 0);
  let cash = [...new Set(sales.map(sale => sale.cash))].reduce((sum, value) => sum + value, 0);
  let mpesa = [...new Set(sales.map(sale => sale.mpesa))].reduce((sum, value) => sum + value, 0);
  let bank = [...new Set(sales.map(sale => sale.bank))].reduce((sum, value) => sum + value, 0);
  const date = [...new Set(sales.map(sale => sale.createdAt))];
  const totalQty = sales.reduce((total, sale) => total + sale.saleqty, 0);
  const totalPrice = sales.reduce((total, sale) => total + (sale.saleqty * sale.saleprice), 0);
  const commission = [...new Set(sales.map(sale => sale.commision))].reduce((sum, value) => sum + value, 0);
  const paid = cash + mpesa + bank + commission;
  const balance = totalPrice - (cash + mpesa + bank);
  // const balance = totalPrice - commission - (cash + mpesa + bank);

  // Check if any item has a cnqty above 0
  const hasCNQty = sales.some(sale => sale.cnqty > 0);
  const hasComplain = complains.some(comp => comp.amount > 0);

  // Calculate the total returned bales and adjust total price accordingly
  const totalReturnedBales = sales.reduce((total, sale) => total + sale.cnqty, 0);
  const adjustedTotalPrice = totalPrice - sales.reduce((total, sale) => total + (sale.cnqty * sale.saleprice), 0);

  // Add commission to the primary payment method (the one with a value greater than 0)
  if (cash > 0) {
    cash += commission;
  } else if (mpesa > 0) {
    mpesa += commission;
  } else if (bank > 0) {
    bank += commission;
  }

  return (
    <div ref={ref} className="print-container">
      <h4 className="text-center fw-bold">INVOICE</h4>
      <h6 className="text-center fw-bold">CUSTOMER: {customer}</h6>
      <h6 className="text-center fw-bold">DATE: {date}</h6>
      <table className="table table-bordered excel-table">
        <thead>
          <tr>
            <th>Item</th>
            <th>Label</th>
            <th>Price</th>
            <th>QTY</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {sales.map((sale, index) => (
            <tr key={index}>
              {/* <td>{sale.itemid.item}</td> */}
              <td>
                {sale.itemid.item.includes("#1") ? sale.itemid.item.replace("#1", "(C)") :
                sale.itemid.item.includes("#2") ? sale.itemid.item.replace("#2", "(P)") :
                sale.itemid.item.includes("#3") ? sale.itemid.item.replace("#3", "(WL)") :
                sale.itemid.item}
              </td>
              <td>{sale.itemid.label}</td>
              <td>{Number(sale.saleprice).toLocaleString()}</td>
              <td>{sale.saleqty}</td>
              <td>{Number(sale.saleqty * sale.saleprice).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>

        {hasComplain && 
          <tbody>
            <tr>
              <td colSpan="5" className='text-center'><strong>Good Complain</strong></td>
            </tr>
            {complains.map((comp, index) => (
              <tr key={index}>
                <td>{comp.itemname}</td>
                <td>{comp.itemlabel}</td>
                <td>{Number(comp.amount).toLocaleString()}</td>
                <td>{comp.qty}</td>
                <td>{Number(comp.qty * comp.amount).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        }

        {hasCNQty && 
          <tbody>
            <tr>
              <td colSpan="5" className='text-center'><strong>BALE RETURN</strong></td>
            </tr>
            {sales.map((sale, index) => (
              sale.cnqty > 0 &&
              <tr key={index}>
                <td>{sale.itemid.item}</td>
                <td>{sale.itemid.label}</td>
                <td>{Number(sale.saleprice).toLocaleString()}</td>
                <td>{sale.cnqty}</td>
                <td>{Number(sale.cnqty * sale.saleprice).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        }

        <thead>
          <tr>
            <td colSpan="4"><strong>Total Bales</strong></td>
            <td><strong>{totalQty}</strong></td>
          </tr>
          <tr>
            <td colSpan="4"><strong>Total Returned Bales</strong></td>
            <td><strong>{totalReturnedBales}</strong></td>
          </tr>
          {hasComplain && 
            <tr>
              <td colSpan="4"><strong>Good Complain</strong></td>
              <td><strong>{Number(totalGoodComplains).toLocaleString()}</strong></td>
            </tr>
          }
          <tr>
            <td colSpan="4"><strong>Total Payable</strong></td>
            <td><strong>{Number(adjustedTotalPrice - totalGoodComplains).toLocaleString()}</strong></td>
          </tr>
          <tr>
            <td colSpan="3"><strong>Payment </strong></td>
            <td><strong>Cash</strong></td>
            <td><strong>{Number(cash).toLocaleString()}</strong></td>
          </tr>
          <tr>
            <td colSpan="3"><strong></strong></td>
            <td><strong>Mpesa</strong></td>
            <td><strong>{Number(mpesa).toLocaleString()}</strong></td>
          </tr>
          <tr>
            <td colSpan="3"><strong></strong></td>
            <td><strong>Bank</strong></td>
            <td><strong>{Number(bank).toLocaleString()}</strong></td>
          </tr>
          <tr>
            <td colSpan="4"><strong>Paid</strong></td>
            <td><strong>{Number(paid).toLocaleString()}</strong></td>
          </tr>
          <tr>
            <td colSpan="4"><strong>Balance</strong></td>
            <td><strong>{Number(balance - totalGoodComplains).toLocaleString()}</strong></td>
          </tr>
        </thead>
      </table>
    </div>
  );
});

export default PrintInvoice;
