import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SidePanel from './Sidepanel';
import Navbar from './Navbar';
import Dashboard from '../pages/dashboard/Dashboard';
import Users from '../pages/homepage/Users';
import Customers from '../pages/homepage/Customers';
import Tasks from '../pages/homepage/Tasks';
import Notes from '../pages/homepage/Notes';
import Orders from '../pages/homepage/Orders';
import { fetchAnalyticsDetails } from '../api/api';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function AnalyticsDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [totalAmount, setTotalAmount] = useState(0);
  const [sales, setSales] = useState([]);
  const [selectedLabel, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchLabelItems();
  }, []);

  const fetchLabelItems = async () => {
    try {
      const sales = await fetchAnalyticsDetails(id);
      setSales(sales);
      const initialTotalAmount = sales.reduce((total, sale) => total + parseFloat(sale.saletotal), 0);
      setTotalAmount(initialTotalAmount);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedSales = sales.map((sale, i) => {
      if (i === index) {
        sale[field] = value;
        sale.saletotal = sale.saleprice * sale.saleqty;
      }
      return sale;
    });
    setSales(updatedSales);
    const newTotalAmount = updatedSales.reduce((total, sale) => total + parseFloat(sale.saletotal), 0);
    setTotalAmount(newTotalAmount);
  };

  const data = {
    labels: sales.map(sale => sale.saleqty),
    datasets: [
      {
        label: 'Sale Quantity',
        data: sales.map(sale => sale.saleqty),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        fill: false
      },
      {
        label: 'Sale Total',
        data: sales.map(sale => sale.saletotal),
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
        fill: false
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'Sales Analytics'
      }
    },
    elements: {
      line: {
        tension: 0.4
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />

        <div className={`container-fluid  my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {activeScreen === 'mastersheet' &&
            <div className='mt-4'>
              <Line data={data} options={options} />

              <h6><u>{} Analytics Details</u></h6>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td><strong>#</strong></td>
                    <th>Item</th>
                    <th>Label</th>
                    <th>QTY</th>
                    <th>Sale Type</th>
                    <th>Status</th>
                    <th>Customer</th>
                  </tr>
                </thead>
                <tbody>
                  {sales.map((sale, index) => (
                    <tr key={sale.id}>
                      <td>{index + 1}</td>
                      <td>{sale.itemid.item}</td>
                      <td>{sale.label}</td>
                      <td>{sale.saleqty}</td>
                      <td>{sale.saletype}</td>
                      <td>{sale.salestatus === 0 ? <p className='text-danger'>Not sold</p> : <p>Sold</p>}</td>
                      <td>{sale.customer}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          }
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
