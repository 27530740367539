import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { Link } from 'react-router-dom'

export default function Reports() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container-fluid my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-4'>
            <h5><u className='titilescolor'>Reports Dashboard</u></h5>

            <div className='row text-center'>
              <div className='col-md-6 mt-3'>
                <Link to='/commisions' style={{textDecoration:  'none'}}>
                    <div class="card low-priority">
                        <div class="card-body">
                        <i class="fa-solid fa-money-check dashboardicons" style={{color: '#e74c3c'}}></i>
                        <h5>Commisions</h5>
                        </div>
                    </div>
                </Link>
              </div>

              <div className='col-md-6 mt-3'>
                <Link to='/salesreport' style={{textDecoration:  'none'}}>
                    <div class="card meduim-priority">
                        <div class="card-body">
                        <i class="fa-solid fa-truck dashboardicons" style={{color: '#2980b9'}}></i>
                        <h5>Sales</h5>
                        </div>
                    </div>
                </Link>
              </div>

              <div className='col-md-12 mt-3'>
                <Link to='/payments' style={{textDecoration:  'none'}}>
                    <div class="card high-priority">
                        <div class="card-body">
                        <i class="fa-solid fa-money-bill-1-wave dashboardicons" style={{color: '#16a085'}}></i>
                        <h5>Payment</h5>
                        </div>
                    </div>
                </Link>
              </div>


            </div>

          </div>}






          {/* the side panel  */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
