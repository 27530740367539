import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllSalesStatus, updateItem, deleteSale, approveSale } from '../../api/api';

export default function Approvesale() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [selectedFile, setSelectedFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const userDesignation = localStorage.getItem('designation'); // Get designation from local storage


  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  const [sales, setSales] = useState([]);
  const [formData, setFormData] = useState({
    item: '',
    itemqty: '',
    buyprice: '',
    sellprice: ''
  });
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchSales();
  }, []);

  const fetchSales = async () => {
    try {
      const salesData = await fetchAllSalesStatus();

      const approvedSales = salesData.filter(sale => sale.approvestatus === 0 && sale.saletype === 'retail');
      setSales(approvedSales);

      // console.log(salesData)
      // setSales(salesData);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateItem(selectedTask.id, formData);
      fetchSales();
      toast.success('Item updated successfully.');
      setSelectedTask(null);
    } catch (error) {
      toast.error('Error updating Item. Please try again.');
    }
  };

  const handleUpdate = (item) => {
    setSelectedTask(item);
    setFormData({
      item: item.item,
      itemqty: item.itemqty
    });
  };

  const handleDelete = async (saleId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this sale?");
    if (isConfirmed) {
      try {
        await deleteSale(saleId);
        fetchSales();
        toast.success('Sale deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Sale. Please try again.');
      }
    }
  };

  const handleApprove = async (saleId) => {
    const isConfirmed = window.confirm("Are you sure you want to approve this sale?");
    if (isConfirmed) {
      try {
        await approveSale(saleId);
        console.log('approving', saleId)
        fetchSales();
        toast.success('Sale approved successfully.');
      } catch (error) {
        toast.error('Error approving Sale. Please try again.');
      }
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Group sales by saleid and calculate totals
  const groupedSales = sales.reduce((acc, curr) => {
    if (!acc[curr.saleid]) {
      acc[curr.saleid] = {
        saleid: curr.saleid,
        qty: 0,
        cash: 0,
        paymentMethod: curr.payment,
        saleType: curr.saletype,
        customer: curr.customer
      };
    }
    acc[curr.saleid].qty += curr.saleqty;
    acc[curr.saleid].cash += curr.saleprice * curr.saleqty;
    return acc;
  }, {});

  // Filter grouped sales based on search query
  // approvestatus

  const filteredSales = Object.values(groupedSales).filter(sale =>
    sale.customer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container-fluid my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-5'>
          
            <input
              className='form-control w-25 float-end'
              type="text"
              placeholder="Search sales..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          <h5><u className='titilescolor'>Approve Sale</u></h5>

          <table className="table table-striped mt-2">
            <thead>
              <tr className='theads'>
                <th><strong>#</strong></th>
                {/* <th>Sale ID</th> */}
                <th>Quantity</th>
                <th>Price</th>
                <th>Sale Type</th>
                <th>Customer</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredSales.length < 1 ? (
                <div>
                  <h5>There are no sales to Approve</h5>
                </div>
              ) : (filteredSales.map((sale, index) => (
                <tr key={sale.saleid}>
                  <td>{index + 1}</td>
                  {/* <td>{sale.saleid}</td> */}
                  <td>{sale.qty}</td>
                  <td>{Number(sale.cash).toLocaleString()}</td>
                  <td>{sale.saleType}</td>
                  <td>{sale.customer}</td>
                  <td>
                    <Link to={`/saledetails/${sale.saleid}`}><i className="fa-solid fa-eye fa-1x" style={{ paddingLeft: '10px'}}></i></Link>
                    {(userDesignation === 'admin' || userDesignation === 'superadmin') && (
                      <>
                        <i className="fa-solid fa-delete-left fa-1x mx-2 deleteicon" onClick={() => handleDelete(sale.saleid)}></i>
                        <i className="fa-solid fa-thumbs-up fa-1x" onClick={() => handleApprove(sale.saleid)} style={{color: 'green'}}></i>
                      </>
                    )}
                  </td>
                </tr>
              )))}
            </tbody>
          </table>
          <ToastContainer />
        </div>
          }
          
          
          
          {/* others begin here  */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
