import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllSales } from '../../api/api';

export default function Balereturn() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [sales, setSales] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  // const getTodayDate = () => {
  //   const today = new Date();
  //   return today.toISOString().split('T')[0];
  // };

  // const [startDate, setStartDate] = useState(getTodayDate()); // Default to today's date
  // const [endDate, setEndDate] = useState(getTodayDate());     // Default to today's date

  useEffect(() => {
    fetchSales();
  }, []);

  const fetchSales = async () => {
    try {
      const sales = await fetchAllSales();
      const filteredSales = sales.filter(sale => sale.cnqty > 0);
      console.log(filteredSales);
      setSales(filteredSales);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const filteredSales = sales.filter(sale => {
    const saleDate = new Date(sale.balereturndate);
    const start = new Date(startDate);
    const end = new Date(endDate);
    return (
      sale.customer.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (!startDate || saleDate >= start) &&
      (!endDate || saleDate <= end)
    );
  });

  // Calculate subtotals
  const subtotal = filteredSales.reduce(
    (acc, sale) => {
      acc.qty += sale.saleqty;
      acc.cnqty += sale.cnqty;
      acc.price += sale.saleprice;
      // acc.total += (sale.saleprice * sale.saleqty);

      return acc;
    },
    { qty: 0, cnqty: 0, price: 0, total: 0 }
  );

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container-fluid my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {activeScreen === 'mastersheet' && 
          <div className='mt-4'>
            <h6><u className='titilescolor'>Bale Return</u></h6>
            <div className="d-flex justify-content-end mb-3">
              <input
                type="text"
                className="form-control w-25 me-2"
                placeholder="Search by customer"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <input
                type="date"
                className="form-control w-25 me-2"
                value={startDate}
                onChange={handleStartDateChange}
              />
              <input
                type="date"
                className="form-control w-25"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
            <table className="table table-striped">
              <thead>
                <tr className='theads'>
                  <td><strong>#</strong></td>
                  <th>Item</th>
                  <th>QTY</th>
                  <th className='text-danger'>CN.QTY</th>
                  <th>Price</th>
                  <th>Total</th>
                  <th>Sale Type</th>
                  <th>Status</th>
                  <th>Customer</th>
                  <th>Return Date</th>
                  <th></th>                
                </tr>
              </thead>
              <tbody>
                {filteredSales.map((sale, index) => (
                  <tr key={sale.id}>
                    <td>{index + 1}</td>
                    <td>{sale.itemid.item}</td>
                    <td>{sale.saleqty}</td>
                    <td className='text-danger'>{sale.cnqty}</td>
                    <td>{Number(sale.saleprice).toLocaleString()}</td>
                    <td>{Number(sale.cnqty * sale.saleprice).toLocaleString()}</td>
                    <td>{sale.saletype}</td>
                    <td>{sale.salestatus === 0 ? <p className='text-danger fw-bold'>Not sold</p> : <p className='text-success fw-bold'>Sold</p>}</td>
                    <td>{sale.customer}</td>
                    <td>{sale.balereturndate}</td>
                  </tr>
                ))}
                
                {/* Subtotal row */}
                <tr className='fw-bold'>
                  <td className='text-success' colSpan="2">Subtotal</td>
                  <td className='text-success'>{subtotal.qty}</td>
                  <td className='text-danger'>{subtotal.cnqty}</td>
                  <td className='text-success'>{Number(subtotal.price).toLocaleString()}</td>
                  {/* <td className='text-success'>{subtotal.total}</td> */}
                  <td colSpan="5"></td>
                </tr>

              </tbody> 
            </table>
            <ToastContainer />
          </div>
          }

          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
