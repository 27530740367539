// Taskform.js
import React from 'react';

const Taskform = ({ formData, handleChange, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <input type="number" className="form-control d-none" id="userid" name="userid" value={formData.userid} onChange={handleChange} />
      <div className="mb-1">
        <label htmlFor="task" className="form-label">Task</label>
        <textarea className="form-control" id="task" name="task" value={formData.task} onChange={handleChange} required></textarea>
        {/* <input type="text" className="form-control" id="task" name="task" value={formData.task} onChange={handleChange} required /> */}
      </div>
      <div className="mb-1">
        <label htmlFor="department" className="form-label">Status</label>
        <select className="form-select" id="status" name="status" value={formData.status} onChange={handleChange} required>
          <option disabled value="">Select Status</option>
          <option value="started">Started</option>
          <option value="notstarted">Not Started</option>
        </select>
      </div>
      <div className="mb-1">
        <label htmlFor="priority" className="form-label">Priority</label>
        <select className="form-select" id="priority" name="priority" value={formData.priority} onChange={handleChange} required>
          <option disabled value="">Select Priority</option>
          <option value="high">High</option>
          <option value="meduim">Meduim</option>
          <option value="low">Low</option>
        </select>
      </div>
      <div className="mb-1">
        <label htmlFor="due" className="form-label">Due date</label>
        <input type="date" className="form-control" id="date" name="due" value={formData.due} onChange={handleChange} required />
      </div>
      <button type="submit" className="btn btn-success btn-sm">Submit</button>
    </form>
  );
};

export default Taskform;
