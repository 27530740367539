import React, {useState, useEffect} from 'react'
import Navbar from '../../components/Navbar'
import SidePanel from '../../components/Sidepanel'
import Dashboard from '../dashboard/Dashboard';
import Users from './Users';
import Customers from './Customers';
import Tasks from './Tasks';
import Notes from './Notes';
import Orders from './Orders';
import Profile from './Profile';

export default function Homepage() {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('dashboard');
  const designation = localStorage.getItem('designation'); // Get designation from local storage

  useEffect(() => {
    // Set default screen based on user's designation
    if (designation === 'customer') {
      setActiveScreen('orders');
    } else {
      setActiveScreen('dashboard');
    }
  }, []);

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    setActiveScreen(screen);
  };

  return (
    <>
      <Navbar />

      <div className='homepage'>
      <SidePanel isOpen={isSidePanelOpen} toggleSidePanel={toggleSidePanel} handleMenuItemClick={handleMenuItemClick}/>

      <div className={`container-fluid my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
        {activeScreen === 'dashboard' && <Dashboard />}
        {activeScreen === 'users' && <Users />}
        {activeScreen === 'customers' && <Customers />}
        {activeScreen === 'tasks' && <Tasks />}
        {activeScreen === 'notes' && <Notes />}
        {activeScreen === 'orders' && <Orders />}
        {activeScreen === 'profile' && <Profile />}
      </div>
      </div>
    </>
  )
}