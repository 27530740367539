import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SidePanel from './Sidepanel';
import Navbar from './Navbar';
import Dashboard from '../pages/dashboard/Dashboard';
import Users from '../pages/homepage/Users';
import Customers from '../pages/homepage/Customers';
import Tasks from '../pages/homepage/Tasks';
import Notes from '../pages/homepage/Notes';
import Orders from '../pages/homepage/Orders'
import { fetchAllSales} from '../api/api';

export default function CommissionDetails() {
  const { id } = useParams(); // Get the sales id from the route parameters
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');

  const [totalAmount, setTotalAmount] = useState(0); // Initialize totalAmount state

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  // logic 
  const [sales, setSales] = useState([]);

  useEffect(() => {
    fetchLabelItems();
  }, []);

  // const fetchLabelItems = async () => {
  //   try {
  //     const sales = await fetchsalesDetails(id);
  //     console.log('sales',sales);
  //     setSales(sales);
  //     // Calculate total amount for initial sales data
  //     const initialTotalAmount = sales.reduce((total, sale) => total + parseFloat(sale.saletotal), 0);
  //     setTotalAmount(initialTotalAmount);
  //   } catch (error) {
  //     console.error('Error fetching sales:', error);
  //   }
  // };

  const fetchLabelItems = async () => {
    try {
        const allSales = await fetchAllSales(); // Fetch all sales

        const today = new Date().toISOString().slice(0, 10); // Get today's date in 'YYYY-MM-DD' format

        const filteredSales = allSales.filter(sale => {
            const saleDate = sale.createdAt.slice(0, 10); // Extract date part of createdAt
            return sale.person === id && saleDate === today;
        });

        console.log('filtered sales', filteredSales);
        setSales(filteredSales);

        // Calculate total amount for initial filtered sales data
        const initialTotalAmount = filteredSales.reduce((total, sale) => total + parseFloat(sale.saletotal), 0);
        setTotalAmount(initialTotalAmount);
    } catch (error) {
        console.error('Error fetching sales:', error);
    }
};

  // Calculate the subtotal for commissions
  const subtotal = sales.reduce((acc, seller) => acc + (seller.saleqty - seller.cnqty), 0);

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />

        <div className={`container-fluid  my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-4'>

            <h6><u className='titilescolor'>Details</u></h6>

            <table className="table table-striped">
              <thead>
                <tr className='theads'>
                  <td><strong>#</strong></td>
                  <th>Item</th>
                  <th>Label</th>
                  <th>QTY</th>
                  {/* <th>Price</th>
                  <th>Total</th> */}
                  <th>Sale Type</th>
                  <th>Status</th>
                  <th>Customer</th>
                  <th></th>                
                </tr>
              </thead>
              <tbody>
                {sales.map((sale, index) => (
                  <tr key={sale.id}>
                    <td>{index + 1}</td>
                    <td>{sale.itemid.item}</td>
                    <td>{sale.label}</td>
                    <td>{sale.saleqty - sale.cnqty}</td>
                    {/* <td>
                      <input
                        className='form-control'
                        type="number"
                        value={sale.saleqty}
                        onChange={(e) => handleInputChange(index, 'saleqty', e.target.value)}
                      />
                    </td> */}
                    {/* <td>
                      <input
                        className='form-control'
                        type="number"
                        value={sale.saleprice}
                        onChange={(e) => handleInputChange(index, 'saleprice', e.target.value)}
                      />
                    </td> */}
                    {/* <td>{sale.saletotal}</td> */}
                    <td>{sale.saletype}</td>
                    <td>{sale.salestatus === 0 ? <p className='text-danger'>Not sold</p> : <p>Sold</p>}</td>
                    <td>{sale.customer}</td>
                  </tr>
                ))}
              </tbody>    
              <tfoot>
                <tr>
                  <td colSpan="1"></td>
                  <td className='text-success' colSpan="2"><strong>Subtotal</strong></td>
                  <td className='text-success'><strong>{subtotal}</strong></td>
                  <td colSpan="2"></td>
                </tr>
              </tfoot>    
            </table>

            {/* <h5 className='text-success fw-bold'>Sub Total: {totalAmount}</h5> */}
          </div>
          }
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  )
}
