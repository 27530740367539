import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Mastersheetform from '../../components/Mastersheetform';
import { fetchAllSales, updateItem, deleteSale, approveSale } from '../../api/api';

export default function Bank() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [selectedFile, setSelectedFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  const [sales, setSales] = useState([]);
  const [formData, setFormData] = useState({
    item: '',
    itemqty: '',
    buyprice: '',
    sellprice: ''
  });
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchSales();
  }, []);

  const fetchSales = async () => {
    try {
      const salesData = await fetchAllSales();
      setSales(salesData);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateItem(selectedTask.id, formData);
      fetchSales();
      toast.success('Item updated successfully.');
      setSelectedTask(null);
    } catch (error) {
      toast.error('Error updating Item. Please try again.');
    }
  };

  const handleUpdate = (item) => {
    setSelectedTask(item);
    setFormData({
      item: item.item,
      itemqty: item.itemqty
    });
  };

  const handleDelete = async (saleId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this sale?");
    if (isConfirmed) {
      try {
        await deleteSale(saleId);
        fetchSales();
        toast.success('Sale deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Sale. Please try again.');
      }
    }
  };

  const handleApprove = async (saleId) => {
    const isConfirmed = window.confirm("Are you sure you want to approve this sale?");
    if (isConfirmed) {
      try {
        await approveSale(saleId);
        fetchSales();
        toast.success('Sale approved successfully.');
      } catch (error) {
        toast.error('Error approving Sale. Please try again.');
      }
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Group sales by saleid and calculate totals
  const groupedSales = sales.reduce((acc, curr) => {
    if (!acc[curr.saleid]) {
      acc[curr.saleid] = {
        saleid: curr.saleid,
        qty: 0,
        cash: 0,
        paymentMethod: curr.payment,
        saleType: curr.saletype,
        customer: curr.customer
      };
    }
    acc[curr.saleid].qty += curr.saleqty;
    acc[curr.saleid].cash += curr.saleprice * curr.saleqty;
    return acc;
  }, {});

  // Filter grouped sales based on search query and payment method
  const filteredSales = Object.values(groupedSales)
  .filter(sale =>
    sale.customer.toLowerCase().includes(searchQuery.toLowerCase()) &&
    sale.paymentMethod.toLowerCase() === 'bank'
  );

  const totalCash = filteredSales.reduce((total, sale) => total + sale.cash, 0);

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container-fluid my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-5'>
          
            <input
              className='form-control w-25 float-end'
              type="text"
              placeholder="Search sales..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          <h5><u>Bank Sales</u></h5>

          <table className="table table-striped mt-2">
            <thead>
              <tr>
                <th><strong>#</strong></th>
                <th>Quantity</th>
                <th>Cash</th>
                <th>Payment Method</th>
                <th>Sale Type</th>
                <th>Customer</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredSales.map((sale, index) => (
                <tr key={sale.saleid}>
                  <td>{index + 1}</td>
                  <td>{sale.qty}</td>
                  <td>{sale.cash}</td>
                  <td>{sale.paymentMethod}</td>
                  <td>{sale.saleType}</td>
                  <td>{sale.customer}</td>
                  <td>
                    {/* <i className="fa-solid fa-pen-to-square fa-1x" onClick={() => handleUpdate(sale)} data-bs-toggle="modal" data-bs-target="#exampleModal2"></i> */}
                    <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h6 className="modal-title fs-5" id="exampleModalLabel2">Update Item</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className="modal-body">
                            <Mastersheetform formData={formData} handleChange={handleChange} handleSubmit={handleUpdateSubmit} />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <i className="fa-solid fa-delete-left fa-1x mx-2" onClick={() => handleDelete(sale.saleid)}></i>
                    <i className="fa-solid fa-thumbs-up fa-1x" onClick={() => handleApprove(sale.saleid)} style={{color: 'green'}}></i> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <h5>Total Cash: {totalCash}</h5>
          <ToastContainer />
        </div>
          }
          
          
          
          {/* others begin here  */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
