// src/utils/logger.js
import log from 'loglevel';

const isProduction = true; // Set to false for development

if (isProduction) {
    log.setLevel('error'); // Only log errors in production
} else {
    log.setLevel('info'); // Log info and below in development
}

// Define log level methods
const logger = {
    trace: (...args) => log.trace(...args),
    debug: (...args) => log.debug(...args),
    info: (...args) => log.info(...args),
    warn: (...args) => log.warn(...args),
    error: (...args) => log.error(...args),
};

// Export the logger
export default logger;
