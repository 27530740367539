import React from 'react'
import { Link } from 'react-router-dom'

export default function Dashboard() {
  const currentYear = new Date().getFullYear();

  return (
    <>
    <div className='row mt-3 text-center'>
        <div className='col-md-4 mt-3'>
            <Link to='/sales' style={{textDecoration:  'none'}}>
            <div class="card">
                <div class="card-body">
                <i class="fa-solid fa-cart-shopping dashboardicons" style={{color: '#d35400'}}></i>
                <h5>Sales</h5>
                </div>
            </div>
            </Link>
        </div>

        <div className='col-md-4 mt-3'>
            <Link to='/approvesale' style={{textDecoration:  'none'}}>
            <div class="card">
                <div class="card-body">
                <i class="fa-solid fa-sheet-plastic dashboardicons" style={{color: '#27ae60'}}></i>
                <h5>Approve Sales</h5>
                </div>
            </div>
            </Link>
        </div>

        <div className='col-md-4 mt-3'>
            <Link to='/invoices' style={{textDecoration:  'none'}}>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-file-invoice-dollar dashboardicons" style={{color: '#2980b9'}}></i>
                    <h5>Invoices</h5>
                    </div>
                </div>
            </Link>
        </div>

        <div className='col-md-4 mt-3'>
            <Link to='/mastersheet' style={{textDecoration:  'none'}}>
            <div class="card">
                <div class="card-body">
                <i class="fa-solid fa-sheet-plastic dashboardicons" style={{color: '#c0392b'}}></i>
                <h5>Mastersheet</h5>
                </div>
            </div>
            </Link>
        </div>

        <div className='col-md-4 mt-3'>
            <Link to='/reports' style={{textDecoration:  'none'}}>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-table dashboardicons" style={{color: '#8e44ad'}}></i>
                    <h5>Reports</h5>
                    </div>
                </div>
            </Link>
        </div>        

        <div className='col-md-4 mt-3'>
            <Link to='/container' style={{textDecoration:  'none'}}>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-boxes-stacked dashboardicons" style={{color: '#16a085'}}></i>
                    <h5>Container</h5>
                    </div>
                </div>
            </Link>
        </div>

        <div className='col-md-4 mt-3'>
            <Link to='/goodcomplain' style={{textDecoration:  'none'}}>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-comments dashboardicons" style={{color: '#1abc9c'}}></i>
                    <h5>Good Complain</h5>
                    </div>
                </div>
            </Link>
        </div>

        <div className='col-md-4 mt-3'>
            <Link to='/balereturn' style={{textDecoration:  'none'}}>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-person-walking-arrow-loop-left dashboardicons" style={{color: '#f39c12'}}></i>
                    <h5>Bale Return</h5>
                    </div>
                </div>
            </Link>
        </div>

        <div className='col-md-4 mt-3'>
            <Link to='/labels' style={{textDecoration:  'none'}}>
                <div class="card">
                    <div class="card-body">
                        <i class="fa-solid fa-tags dashboardicons" style={{color: '#e74c3c'}}></i>
                        <h5>Labels</h5>
                    </div>
                </div>
            </Link>
        </div>

        <div className='col-md-6 mt-3'>
            <Link to='/expenses' style={{textDecoration:  'none'}}>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-money-bill dashboardicons" style={{color: '#f1c40f '}}></i>
                    <h5>Expenses</h5>
                    </div>
                </div>
            </Link>
        </div>

        <div className='col-md-6 mt-3'>
            <Link to='/analytics' style={{textDecoration:  'none'}}>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-chart-simple dashboardicons" style={{color: '#2c3e50'}}></i>
                    <h5>Analytics</h5>
                    </div>
                </div>
            </Link>
        </div>

        {/* <div className='col-md-4 mt-3'>
            <Link to='/receivables'>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-truck dashboardicons"></i>
                    <h5>Receivables & Payables</h5>
                    </div>
                </div>
            </Link>
        </div> */}

        

        {/* <div className='col-md-4 mt-3'>
            <Link to='/cashpayment'>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-building-columns dashboardicons"></i>
                    <h5>Cash</h5>
                    </div>
                </div>
            </Link>
        </div> */}

        {/* <div className='col-md-4 mt-3'>
            <Link to='/bank'>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-building-columns dashboardicons"></i>
                    <h5>Bank</h5>
                    </div>
                </div>
            </Link>
        </div> */}

        {/* <div className='col-md-4 mt-3'>
            <Link to='/mpesa'>
                <div class="card">
                    <div class="card-body">
                    <i class="fa-solid fa-mobile-screen dashboardicons"></i>
                    <h5>Mpesa</h5>
                    </div>
                </div>
            </Link>
        </div> */}     


        <footer className='footer' style={{ fontSize: '0.7rem' }}>
            <p className='mt-5'>Copyright &copy; {currentYear} All rights reserved</p>
            <p style={{ marginTop: '-1rem' }}>
                Powered by <a href="https://www.aptech.co.ke" className='fw-bold' target="_blank" rel="noopener noreferrer">AP Tech Kenya</a>
            </p>
        </footer>

        </div>
    </>
  )
}
