import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Mastersheetform from '../../components/Mastersheetform';
import { fetchAllItemsMaster, updateMaxnum } from '../../api/api';
import Addmaxform from '../../components/Addmaxform';

export default function Analytics() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [searchQuery, setSearchQuery] = useState('');
  const [containers, setContainers] = useState([]);
  const [selectedContainer, setSelectedContainer] = useState('');

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  // Items logic starts here 
  const [items, setItems] = useState([]);
  const [formData, setFormData] = useState({
    maxnum: '',
    user: localStorage.getItem('username')
  });

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const items = await fetchAllItemsMaster();
      console.log(items);
      setItems(items);
      // Extract unique container names from items array
      const uniqueContainers = [...new Set(items.map(item => item.label))];
      setContainers(uniqueContainers);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateMaxnum(formData);
      fetchItems();
      toast.success('Max num added successfully.');
    } catch (error) {
      toast.error('Error adding maxnum. Please try again.');
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter items based on search query
  const filteredItems = items.filter(item =>
    item.item.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Function to handle container selection
  const handleContainerSelect = (container) => {
    setSelectedContainer(container);
  };

  // Filter items based on selected container
  const filteredItemsByContainer = selectedContainer
    ? items.filter(item => item.label === selectedContainer && item.status === 1)
    : items.filter(item => item.status === 1);

  // Filter items based on search query
  const filteredItemsBySearch = filteredItemsByContainer.filter(item =>
    item.item.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // CSV headers
  const csvHeaders = [
    { label: "Item", key: "item" },
    { label: "Label", key: "label" },
    { label: "In", key: "inqty" },
    { label: "Out", key: "out" },
    { label: "Balance", key: "masterqty" }
  ];

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        <div className={`container-fluid my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Mastersheet */}
          {activeScreen === 'mastersheet' &&
            <div className='mt-5'>
              <div className="modal fade" id="exampleModalcsv" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h6 className="modal-title fs-5" id="exampleModalLabel">Import CSV</h6>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <Mastersheetform formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h6 className="modal-title fs-5" id="exampleModalLabel">Add Item</h6>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <Mastersheetform formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
                    </div>
                  </div>
                </div>
              </div>

                <button className='btn btn-warning btn-sm float-end mx-1' data-bs-toggle="modal" data-bs-target="#exampleModal2">Add Num</button>

                <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title fs-5" id="exampleModalLabel2">Add Num</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <Addmaxform formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
                    </div>
                    </div>
                </div>
                </div>

              <CSVLink
                  data={filteredItemsBySearch}
                  headers={csvHeaders}
                  filename={"mastersheet-report.csv"}
                  className="btn btn-success btn-sm mx-1 float-end"
                >
                  Export to CSV
              </CSVLink>

              <input
                className='form-control w-25 float-end'
                type="text"
                placeholder="Search items..."
                value={searchQuery}
                onChange={handleSearchChange}
              />

              <h5><u>Analytics Page</u></h5>

              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Balance</th>
                    <th>Movement</th>
                  </tr>
                </thead>
                <tbody>
                  {containers.length < 1 ? (
                    <tr>
                      <td colSpan="5">
                        <h5>Mastersheet has zero data</h5>
                      </td>
                    </tr>
                  ) : (filteredItemsBySearch.map((item, index) => (
                    <tr key={item.id} className={item.masterqty > item.maxnum ? 'table-danger' : ''}>
                      <td>{item.item}</td>
                      <td>{item.masterqty}</td>
                      <td>{item.masterqty > item.maxnum ? 'slow' : 'fast'}</td>
                      <td>
                        <Link to={`/analyticsdetails/${item.id}`}><i className="fa-solid fa-eye fa-1x" style={{ paddingLeft: '10px'}}></i></Link>
                      </td>
                    </tr>
                  )))}
                </tbody>
              </table>
              <ToastContainer />
            </div>
          }

          {/* Side panel components */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
