import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Customerform from '../../components/Customerform';
import { Link } from 'react-router-dom';
import { fetchAllCustomers, createCustomer, updateCustomer, deleteCustomer } from '../../api/api';

export default function Customers() {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    type: '',
    designation: 'customer',
    credit_limit: '',
    password: ''
  });
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    filterCustomers();
  }, [customers, searchTerm]);

  const fetchCustomers = async () => {
    try {
      const customers = await fetchAllCustomers();
      setCustomers(customers);
      console.log('customers', customers)
      setLoading(false);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  const filterCustomers = () => {
    if (searchTerm === '') {
      setFilteredCustomers(customers);
    } else {
      setFilteredCustomers(customers.filter(customer =>
        customer.name.toLowerCase().includes(searchTerm.toLowerCase())
      ));
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createCustomer(formData);
      fetchCustomers();
      toast.success('Customer added successfully!');
    } catch (error) {
      // toast.error('Error adding customer. Please try again.');
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.message;
        if (errorMessage.includes('email')) {
          toast.warn('Customer with this email or username already exists.');
        } else if (errorMessage.includes('name')) {
          toast.warn('Customer with this username or email already exists.');
        }
      } else {
        toast.error('Error adding Customer. Please try again.');
      }
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateCustomer(selectedCustomer.id, formData);
      fetchCustomers();
      toast.success('Customer updated successfully.');
      setSelectedCustomer(null);
    } catch (error) {
      toast.error('Error updating customer. Please try again.');
    }
  };

  const handleUpdate = (customer) => {
    setSelectedCustomer(customer);
    setFormData({
      name: customer.name,
      phone: customer.phone,
      email: customer.email,
      type: customer.type,
      designation: customer.designation,
      credit_limit: customer.credit_limit,
      password: ''
    });
  };

  const handleDelete = async (customerId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this customer?");
    if (isConfirmed) {
      try {
        await deleteCustomer(customerId);
        fetchCustomers();
        toast.success('Customer deleted successfully.');
      } catch (error) {
        toast.error('Error deleting customer. Please try again.');
      }
    }
  };

  return (
    <>
      <div className='container-fluid mt-5'>
        
        <button className='btn btn-success float-end btn-sm' data-bs-toggle="modal" data-bs-target="#exampleModal">Create Customer</button>
        
        <input
          type='text'
          className='form-control mb-3 float-end w-25 mx-1'
          placeholder='Search by customer name'
          value={searchTerm}
          onChange={handleSearchChange}
        />

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title fs-5" id="exampleModalLabel">Create Customer</h6>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <Customerform formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
              </div>
            </div>
          </div>
        </div>

        <h5><u className='titilescolor'>Customers</u></h5>
        <table className="table table-striped">
          <thead>
            <tr className='theads'>
              <td><strong>#</strong></td>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Type</th>
              <th>Credit Limit</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredCustomers.map((customer, index) => (
              <tr key={customer.id}>
                <td>{index + 1}</td>
                <td>{customer.name}</td>
                <td>{customer.email}</td>
                <td>{customer.phone}</td>
                <td>{customer.type}</td>
                <td>{customer.credit_limit}</td>
                <td>
                  <Link to={`/your-orders2/${customer.name}`}>
                    <i className="fa-solid fa-eye detailsicon"></i>
                  </Link>
                  <i className="fa-solid fa-pen-to-square fa-1x mx-2 editicon" onClick={() => handleUpdate(customer)} data-bs-toggle="modal" data-bs-target="#exampleModal2"></i>
                  <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h6 className="modal-title fs-5" id="exampleModalLabel2">Customer User</h6>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                          <Customerform formData={formData} handleChange={handleChange} handleSubmit={handleUpdateSubmit} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {customer.saleid === '0' && (
                    <i
                      className="fa-solid fa-delete-left fa-1x deleteicon"
                      onClick={() => handleDelete(customer.id)}
                    ></i>
                  )}
                  {/* <i className="fa-solid fa-delete-left fa-1x deleteicon" onClick={() => handleDelete(customer.id)}></i> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ToastContainer />
      </div>
    </>
  );
}
