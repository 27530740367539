import React, {useState} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { updateCustomer, updateUser } from '../../api/api';

export default function Profile() {
  const cookies = new Cookies();
  const designation = localStorage.getItem('designation');

  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [phone, setPhone] = useState(localStorage.getItem('phone') || '');
  const [password, setPassword] = useState('');

  const submitloginformforcustomer = async (e) => {
  const userId = localStorage.getItem('userid');
    try {
      e.preventDefault();
      const userData = { email, phone, password, userId };
      // const response = await axios.patch(`https://backend.aptech.co.ke/updateCustomer/${userId}`, userData);
      // const response = await axios.patch(`http://localhost:8080/updateCustomer/${userId}`, userData);
    //   console.log(response)
      const response = await updateCustomer(userId, userData);

      if (response.status === 200) {
        
        localStorage.removeItem('userid');
        localStorage.removeItem('token');
        localStorage.removeItem('designation');
        localStorage.removeItem('username');

        // Clear cookies
        cookies.remove('jwt');
        cookies.remove('designation');
        window.location.href = '/'; // Redirect to the desired logout page or home page      } else {
        toast.error('Updated failed. Please check your credentials.');
      } else if(response.status === 400) {
        toast.error('The email already exists.');
      } 
    } catch (error) {
      console.error('Error on profil page:', error);
      toast.error('Update failed. Please check your credentials.');
    }
  };

  const submitloginformforuser = async (e) => {
    const userId = localStorage.getItem('userid');
      try {
        e.preventDefault();
        const userData = { email, phone, password, userId };
        // const response = await axios.post(`https://backend.aptech.co.ke/updateUser/${userId}`, userData);
        // const response = await axios.patch(`http://localhost:8080/updateUser/${userId}`, userData);
      //   console.log(response)
        const response = await updateUser(userId, userData);

        if (response.status === 200) {
          
          localStorage.removeItem('userid');
          localStorage.removeItem('token');
          localStorage.removeItem('designation');
          localStorage.removeItem('username');
  
          // Clear cookies
          cookies.remove('jwt');
          cookies.remove('designation');
          window.location.href = '/'; // Redirect to the desired logout page or home page      } else {
          toast.error('Updated failed. Please check your credentials.');
  
        } else if(response.status === 400) {
            toast.error('The email already exists.');
        } 
      } catch (error) {
        console.error('Error on profil page:', error);
        toast.error('Update failed. Please check your credentials.');
      }
    };
  return (
    <>
      <div className='container-fluid mt-4'>
        <h5>Your Profile</h5>
        {designation === 'customer' ? (
        <div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-subtitle mb-2 text-body-secondary">Username: <span className='text-black'>{localStorage.getItem('name')}</span> </h5>
              <h6 className="card-subtitle mb-2 text-body-secondary">Email: <span className='text-black'>{localStorage.getItem('email')}</span></h6>
              <h6 className="card-subtitle mb-2 text-body-secondary">Phone: <span className='text-black'>{localStorage.getItem('phone')}</span></h6>
              <h6 className="card-subtitle mb-2 text-body-secondary">Designation: <span className='text-black'>{localStorage.getItem('designation')}</span></h6>
              <h6 className="card-subtitle mb-2 text-body-secondary">Credit Limit: <span className='text-black'>{localStorage.getItem('credit_limit')}</span></h6>
            </div>
        </div>

            {/* modal for editing password  */}
            <button type="button" class="btn btn-warning mt-3" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Edit
            </button>

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Edit Details</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form onSubmit={submitloginformforcustomer}>
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Email address</label>
                            <input
                            className="form-control"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            />
                        </div>
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Phone</label>
                            <input
                            className="form-control"
                            type="number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                            />
                        </div>
                        <div class="mb-3">
                            <label for="exampleInputPassword1" class="form-label">Password</label>
                            <input
                            className="form-control"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            />
                        </div>
        
                        <button type="submit" class="btn btn-success">Submit</button>
                    </form>
                </div>
                </div>
            </div>
          </div>
          </div>
        ) : (
            <div>
            <div className="card">
            <div className="card-body">
              <h5 className="card-subtitle mb-2 text-body-secondary">Username: <span className='text-black'>{localStorage.getItem('username')}</span> </h5>
              <h6 className="card-subtitle mb-2 text-body-secondary">Email: <span className='text-black'>{localStorage.getItem('email')}</span></h6>
              <h6 className="card-subtitle mb-2 text-body-secondary">Phone: <span className='text-black'>{localStorage.getItem('phone')}</span></h6>
              <h6 className="card-subtitle mb-2 text-body-secondary">Designation: <span className='text-black'>{localStorage.getItem('designation')}</span></h6>
            </div>
          </div>

          <button type="button" class="btn btn-warning mt-3" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Edit
            </button>

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Edit Details</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form onSubmit={submitloginformforuser}>
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Email address</label>
                            <input
                            className="form-control"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            />
                        </div>
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Phone</label>
                            <input
                            className="form-control"
                            type="number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                            />
                        </div>
                        <div class="mb-3">
                            <label for="exampleInputPassword1" class="form-label">Password</label>
                            <input
                            className="form-control"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            />
                        </div>
        
                        <button type="submit" class="btn btn-success">Submit</button>
                    </form>
                </div>
                </div>
            </div>
          </div>

          </div>
        )}
      </div>
      <ToastContainer />
    </>
  )
}
